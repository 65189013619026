<template>
	<div style="flex-grow: 1;" ref="tableBox">
		<el-table v-loading="loading" size="small" header-cell-class-name="tableHeader" :data="listData" border
			:height="tableHeight" style="width: 100%;">
			<el-table-column label="设备号">
				<template #default="scope">
					<div style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">{{ scope.row.deviceSn }}
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="kaHao" label="卡号">
			</el-table-column>
			<el-table-column prop="createTime" label="交易日期">
			</el-table-column>
			<el-table-column prop="sum" label="金额" width="120">
			</el-table-column>
			<el-table-column prop="beizhu" label="归属平台" width="120">
			</el-table-column>
		</el-table>

		<div style="margin-top: 20px;">
			<div style="display: flex; flex-direction: row;">
				<div style="flex-grow: 1;">
					<el-button @click="exportKaoHao" v-if="state == 0" size="mini" type="primary">导出卡号</el-button>
				</div>
				<el-pagination v-model:currentPage="currentPage" :page-sizes="[30]" :page-size="30"
					layout="total, sizes, prev, pager, next, jumper" :total="count" @size-change="handleSizeChange"
					@current-change="handleCurrentChange">
				</el-pagination>
			</div>

		</div>
	</div>
</template>

<script>
	import {
		getXufeiList,
		getXuFeiCount,
		exportXuFeiData
	} from "../../api/pay.js"
	export default {
		data() {
			return {
				nowItem: {},
				showInfo: false,
				tableHeight: 300,
				currentPage: 1,
				count: 0,
				limit: 30,
				listData: [],
				searchParams: {},
				loading: false
			}
		},

		props: {
			state: Number
		},

		mounted() {
			var that = this
			this.tableInit()
			this.getTableList()
			getXuFeiCount({
				state: that.state
			}).then(res => {
				that.count = res.data.data
			})
		},

		methods: {
			getTableList: function() {
				let that = this
				getXufeiList({
					start: (that.currentPage - 1) * that.limit,
					state: that.state
				}).then(res => {
					that.listData = res.data.data
				})
			},

			tableInit: function() {
				var that = this
				setTimeout(function() {
					let tableBox = that.$refs.tableBox
					that.tableHeight = tableBox.offsetHeight - 60
					console.log(that.tableHeight)
					setTimeout(function() {
						tableBox.children[0].style.maxHeight = that.tableHeight + "px"
					}, 0)
				}, 100)

			},

			exportKaoHao: function() {
				var that = this
				exportXuFeiData().then(res => {
					console.log(res)
					that.$confirm('已成功导出', '提示', {
						distinguishCancelAndClose: true,
						confirmButtonText: '卡号分类',
						cancelButtonText: '下载txt文件'
					}).then(() => {
						console.log("1")
					})
				})
			}
		}
	}
</script>

<style scoped="scoped">
</style>
